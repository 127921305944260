import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"
import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import Collapsable from "../../components/Collapse/Collapse"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Spicy Plant Based Nuggets</title>
          <meta
            name="description"
            content="Our Raised & Rooted™ Spicy Chicken Nuggets are made with pea protein and bring the heat! Try these boldly seasoned plant based spicy nuggets today!"
          />
          <meta itemProp="name" content="Spicy Plant Based Nuggets" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              headingTitleColor1="#006B2D"
              headingText1="Spicy Plant Based Nuggets"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="We're giving our nuggets a flavorful, new kick. Raised & Rooted™ Spicy Nuggets are made with plant based protein and boldly seasoned with cayenne pepper & paprika. That’s right. We’re bringing the heat to the frozen aisle."
              itemColor="#006B2D"
              valueText1="10"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="5"
              unitText2="g"
              labelText2="Fiber per serving"
              valueText3="33"
              unitText3="%"
              labelText3="Less Saturated Fat*"
              dataImg4="/leaf.svg"
              labelText4="Soy Free"
              dataImg5="/leaf.svg"
              labelText5="MADE WITH PLANT PROTEIN"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Than USDA Data For White Meat Nuggets"
              footnoteColor="#006B2D"
              productID="00053400000064"
            >
              <img src="/raised-and-rooted-spicy-plant-based-nuggets-708x721.png" alt="Raised And Rooted Spicy Plant Based Nuggets" />
            </Hero>
            <Content
              ctaClassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-spicy-plant-based-nuggets-708x721-ls.png"
              altText="Raised And Rooted Spicy Plant Based Nuggets"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Raised & Rooted™ Spicy Nuggets are made with pea protein and golden flaxseed, and wrapped in our crispiest, boldest breading yet."
            >
              <h4>NUTRITION</h4>
              <p>
                10 grams of protein and 5 grams of fiber per serving. They also
                have 33% less saturated fat than a regular USDA white meat
                chicken nugget but are 100% as tasty and dunkable.
              </p>
              <h4>TASTE</h4>
              <p>
                Don’t worry. We didn’t just pack them with plants. We packed
                them full of everything you love about nuggets with a bold,
                flavorful kick that you won’t soon forget.
              </p>
            </Content>
            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <b>HEATING INSTRUCTIONS</b>
                  <p>
                    Keep frozen until ready to use. Appliances vary. Heating
                    times approximate.
                  </p>
                  <b>Conventional OVEN</b>
                  <p>
                    Preheat oven to 400°F. Arrange nuggets on a baking sheet
                    about 1-inch apart. Bake for 10-12 minutes or until hot.
                  </p>
                  <b>MICROWAVE</b>
                  <p>
                    Arrange 4 nuggets on a microwave-safe plate. Microwave on
                    high for 55-65 seconds or until hot. Let stand for 1 minute,
                    before serving.
                  </p>
                </Collapsable>
                <br />

                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>2.5 Servings Per Container</p>
                  <p>Serving Size 4 pieces (90g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 220</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 11g</td>
                      <td>14%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1.5g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 5g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 4g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 580mg</td>
                      <td>25%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 21g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 5g</td>
                      <td>18%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 1g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 0g Added Sugars</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Protein 10g</td>
                      <td>13%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0.4mcg</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td>Calcium 220mg</td>
                      <td>15%</td>
                    </tr>
                    <tr>
                      <td>Iron 2.1mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Potassium 180mg</td>
                      <td>4%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *Percent daily values are based on a 2,000 calorie diet.
                        Your daily values may be higher or lower depending on
                        your calorie needs.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"INGREDIENTS"}
                collapsed={true}
              >
                <p>
                  SEASONED PLANT PROTEIN PORTION: VEGAN FIBERS (WATER, PEA
                  PROTEIN ISOLATE, CANOLA OIL, SODIUM ALGINATE, CITRUS FIBER,
                  CALCIUM CHLORIDE), WATER, OAT FIBER, VITAL WHEAT GLUTEN, PEA
                  PROTEIN CONCENTRATE, SEASONING [MALTODEXTRIN (FROM CORN),
                  SALT, NATURAL FLAVOR, YEAST EXTRACT, PALM OIL, CARROT FIBER,
                  CITRIC ACID, MEDIUM CHAIN TRIGLYCERIDES, ONION POWDER, TORULA
                  YEAST, ONION JUICE CONCENTRATE, SPICE, CANOLA OIL], FLAXSEED,
                  METHYLCELLULOSE, SALT, NATURAL FLAVORING (SUNFLOWER OIL AND
                  ROSEMARY EXTRACT). BREADED WITH: WHEAT FLOUR, WATER, CORN
                  STARCH, YELLOW CORN FLOUR, SALT, LEAVENING (CREAM OF TARTAR,
                  SODIUM BICARBONATE), WHEAT GLUTEN, DEXTROSE, SPICES, GARLIC
                  POWDER, EXTRACTIVES OF PAPRIKA AND ANNATTO, SUGAR, BEET
                  POWDER, HOT SAUCE (AGED CAYENNE PEPPERS, VINEGAR, SALT, GARLIC
                  POWDER), MALTODEXTRIN, YEAST, NATURAL FLAVORS. BREADING SET IN
                  VEGETABLE OIL (INCLUDING SOYBEAN OIL).
                </p>
                <H4 headingText="Allergens" />
                <ul className="allergens">
                  <li>Contains: WHEAT</li>
                </ul>
                <p>INGREDIENT(S) DERIVED FROM A BIOENGINEERED SOURCE</p>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000064" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
